import './SmartMenu.css';
import './blind.css';

import classNames from 'classnames';
import React, {useEffect, useRef, useState} from 'react';

import collapse from '../../assets/menu/collapse-btn.svg';
import expand from '../../assets/menu/expand-btn.svg';
import topLogo from '../../assets/menu/menu-top.svg';
import {strapiGetMenuItems} from '../../helpers/strapi';
import {getBottomMenuItems, parseStrapiMenuItems} from './_menuItems';
import MenuElement from './MenuElements/MenuElement';

export const SmartMenu = ({useKeycloak}) => {
  const [menuItems, setMenuItems] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [autoExpanded, setAutoExpanded] = useState(false);
  const appConfig = {LMS_BASE_URL: process.env.REACT_APP_LMS_BASE_URL};
  const {keycloak} = useKeycloak();
  const bottomMenuItems = getBottomMenuItems(appConfig);
  // const [subMenu, setSubMenu] = useState<IMenuItem[]>([]);
  // const [subTitle, setSubTitle] = useState('');
  const blindButton = useRef(null);

  useEffect(() => {
    strapiGetMenuItems().then((res) => {
      const strapiMenuItems = res.data;
      setMenuItems(removeUnaccessible(parseStrapiMenuItems(strapiMenuItems)));
    });
  }, []);

  const isMenuItemAccessible = (item) => {
    if (!item.keycloakRole) return true;
    return keycloak.hasRealmRole(item.keycloakRole);
  };

  // убираем пустые и недоступные секции
  const removeUnaccessible = (itemList) => {
    const items = itemList.filter((item) => isMenuItemAccessible(item));
    for (let i = 0; i < items.length - 1; i++) {
      // Удаляем элемент, если текущий и следующий - isSeparator
      if (items[i].isSeparator && items[i + 1].isSeparator) {
        items.splice(i, 1);
        i--; // Возвращаем индекс на 1 назад, чтобы не пропустить следующий элемент
      }
      if (items[i].submenu && items[i].submenu.length) {
        items[i].submenu = items[i].submenu.filter((subitem) => isMenuItemAccessible(subitem));
        if (!items[i].submenu.length) {
          items.splice(i, 1);
          i--;
        }
      }
    }
    // Удаляем последний элемент, если он isSeparator
    if (items.length > 0 && items[items.length - 1].isSeparator) {
      items.pop();
    }

    return items;
  };

// deprecated
//   const onMenuClick = (submenuItems: IMenuItem[], title: string, event: React.MouseEvent<HTMLAnchorElement>) => {
//     if (submenuItems?.length) {
//       event.preventDefault();
//       if (title !== subTitle) {
//         setSubMenu(submenuItems);
//         setSubTitle(title);
//       } else {
//         setSubMenu([]);
//         setSubTitle('');
//       }
//     }
//   };

  const handleMouseEnter = () => {
    setAutoExpanded(true);
  };
  const handleMouseLeave = () => {
    // setSubMenu([]);
    // setSubTitle('');
    setAutoExpanded(false); // ?
  };

  const blindMenuItem = {
    title: 'Версия для слабовидящих',
    link: '#',
    id: 'specialButton',
    img: `${appConfig.LMS_BASE_URL}/static/smart-design/images/blind.svg`,
  };

  return (
    <div className={classNames('menu', {'expanded': expanded || autoExpanded})}>
      <img src={topLogo} alt="SmartMenu" className="menuLogo"/>
      <button className="expandButton" onClick={() => setExpanded(!expanded)}>
        <img src={expanded ? collapse : expand} alt='Expand'/>
      </button>
      <ul className="menuUl scroll scroll-b"
          onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter}>
        {menuItems.length > 0 ? (
          menuItems.map((menuItem, index) => (
            // isMenuItemAccessible(menuItem) && ( // ушло в removeUnaccessible
            <MenuElement element={menuItem} key={index}/>
            // )
          ))
        ) : (
          <span>No menu!</span>
        )}
        <li className="spacer"></li>
        {bottomMenuItems.map((menuItem, index) => (
          <MenuElement element={menuItem} key={index}/>
        ))}
        {/* версия для слабовидящих */}
        {/*<li id="specialButton" key="blindButton" ref={blindButton} className="menuElementLi">*/}
        {/*  <a href={blindMenuItem.link} className="menuElementA">*/}
        {/*    <img alt={blindMenuItem.title} src={blindMenuItem.img} />*/}
        {/*    <div className="popupTitle">{blindMenuItem.title}</div>*/}
        {/*  </a>*/}
        {/*</li>*/}
      </ul>
      {/* старое подменю */}
      {/*{subMenu.length > 0 && (*/}
      {/*  <ul className="submenuUl">*/}
      {/*    <h3>{subTitle}</h3>*/}
      {/*    <hr />*/}
      {/*    {subMenu.map((menuItem, index) => (*/}
      {/*      isMenuItemAccessible(menuItem) && (*/}
      {/*        <li key={index}>*/}
      {/*          <a href={menuItem.link}>{menuItem.title}</a>*/}
      {/*        </li>*/}
      {/*      )*/}
      {/*    ))}*/}
      {/*  </ul>*/}
      {/*)}*/}
    </div>
  );
};

export default SmartMenu;
