/* eslint-disable @typescript-eslint/no-explicit-any */
import './MenuElement.css';

import classNames from 'classnames';
import {useState} from 'react';

import open from '../../../assets/menu/submenuOpen.svg';
import SubMenu from './SubMenu';

const MenuElement = ({ element }) => {
  const { link, img, title, style, svg, active, submenu, isOpen } = element;
  const [showSubmenu, setShowSubmenu] = useState(isOpen);
  if (element.isSeparator) return (
    <div className="menuSeparator">
      <span className='separatorTitle'>{title}</span><hr />
    </div>
  );
  return (
    <li className="menuElementLi">
      <a
        href={link}
        className={classNames('menuElementA', { 'linkActive': active })}
        onClick={() => setShowSubmenu(!showSubmenu)}
        // onClick={(evt) => onClickFn(submenu, title, evt)}
      >
        {svg ? (
          <span dangerouslySetInnerHTML={{ __html: img }} />
        ) : (
          <img alt={title} src={img} style={style} />
        )}
        <div className="popupTitle">{title}</div>
        {submenu.length>0 && (<img alt="open" src={open} className='submenuOpenBtn'/>)}
      </a>
      {showSubmenu && (
        <SubMenu subMenu={submenu}/>
      )}
    </li>
  );
};

export default MenuElement;
