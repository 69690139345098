/* eslint-disable @typescript-eslint/no-explicit-any */
const baseurl = process.env.REACT_APP_STRAPI_BASE_URL

async function fetchStrapi(url) {
  const options = {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + process.env.REACT_APP_STRAPI_TOKEN,
    },
  };
  const response = await fetch(baseurl + url, options);
  return response.json();
}

export function strapiGetMenuItems() {
  return fetchStrapi('/menus?populate[0]=submenu');
}

export async function getMenuItems3() {
  return await strapiGetMenuItems();
}

export async function TestStrapiGetMenuItems(token) {
  const options = {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
    },
  };
  const response = await fetch(`${baseurl}/menus?populate[0]=submenu`, options);
  return response.json();
}
