import './SubMenu.css';

export default function SubMenu(props) {
  const {subMenu} = props;
  if(!subMenu.length) return null;
  return (
    <div className="submenuBox">
      <ul className="submenuUl">
        {subMenu.map((menuItem, index) => (
          <li key={index}>
            <a href={menuItem.link}>{menuItem.title}</a>
          </li>
        ))}
      </ul>
    </div>
  );
}

