import React from 'react';
import App from 'App';
import paths, { rootPaths } from './paths';
import { Navigate, createBrowserRouter } from 'react-router-dom';

import MainLayout from '../layouts/MainLayout';
import ErrorLayout from '../layouts/ErrorLayout';
import Landing from 'components/pages/landing/Landing';
import Invoice from 'components/app/e-commerce/Invoice';
import Billing from 'components/app/e-commerce/billing/Billing';
import Checkout from 'components/app/e-commerce/checkout/Checkout';
import ShoppingCart from 'components/app/e-commerce/cart/ShoppingCart';
import CustomersDetails from 'components/app/e-commerce/customers-details/CustomersDetails';
import OrderDetails from 'components/app/e-commerce/orders/order-details/OrderDetails';
import Products from 'components/app/e-commerce/product/Products';
import ProductDetails from 'components/app/e-commerce/product/product-details/ProductDetails';
import AddProduct from 'components/app/e-commerce/product/add-product/AddProduct';
import Orders from 'components/app/e-commerce/orders/order-list/Orders';
import Customers from 'components/app/e-commerce/customers/Customers';
import Courses from 'components/app/e-learning/course/Courses';
import CourseDetails from 'components/app/e-learning/course/course-details';
import CreateCourse from 'components/app/e-learning/course/create-a-course';
import TrainerProfile from 'components/app/e-learning/trainer-profile';
import StudentOverview from 'components/app/e-learning/student-overview';
import Ecommerce from 'components/dashboards/e-commerce';
import Lms from 'components/dashboards/lms';

import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';

import Dashboard from 'components/dashboards/default';
import ChatMainLayout from "../layouts/ChatMainLayout";
import PagesProvider from "../components/app/smart-chat/pagesProvider/PagesProvider";
import PrivateMainLayoutRoute from "./privateMainLayoutRoute";
import keycloakRealmRoles from "../helpers/keycloakRealmRoles";
import PrivateRoute from "./privateRoute";
import GoodsSales from "../components/app/e-commerce/GoodsSales";
import SalesVolume from "../components/app/e-commerce/SalesVolume";
import RevenueByMonth from "../components/app/e-commerce/RevenueByMonth";
import IndicatorsCumulativeTotalByMonths from "../components/app/e-commerce/IndicatorsCumulativeTotalByMonths";
import SummarizedSalesFigures from "../components/app/e-commerce/SummarizedSalesFigures";
import ProjectStructures from "../components/app/kinescope/ProjectStructures";

const routes = [
  {
    element: <App />,
    children: [
      {
        path: 'landing',
        element: <Landing />
      },
      {
        path: rootPaths.errorsRoot,
        element: <ErrorLayout />,
        children: [
          {
            path: paths.error404,
            element: <Error404 />
          },
          {
            path: paths.error500,
            element: <Error500 />
          }
        ]
      },
      {
        path: '/',
        element:
          <PrivateMainLayoutRoute>
            <MainLayout />
          </PrivateMainLayoutRoute>,
        children: [
          {
            index: true,
            element:
              <PrivateRoute
                requiredRoles={[]}
                pageName={'чат'}
              >
                {/*<Dashboard />*/}
                <PagesProvider>
                  <ChatMainLayout />
                </PagesProvider>
              </PrivateRoute>
          },
          /*{
            path: rootPaths.dashboardRoot,
            children: [
              {
                path: paths.ecommerce,
                element:
                  <PrivateRoute
                    requiredRoles={[keycloakRealmRoles.DASHBOARD_STATISTICS]}
                    pageName={'дашборд Ecommerce'}
                  >
                    <Ecommerce />
                  </PrivateRoute>
              },
              {
                path: paths.lms,
                element:
                  <PrivateRoute
                    requiredRoles={[keycloakRealmRoles.DASHBOARD_STATISTICS]}
                    pageName={'дашборд LMS'}
                  >
                    <Lms />
                  </PrivateRoute>
              }
            ]
          },*/
          {
            path: rootPaths.appsRoot,
            children: [
              {
                path: paths.chat,
                element:
                  <PagesProvider>
                      <ChatMainLayout />
                  </PagesProvider>
              },
            ]
          },
          /*{
            path: rootPaths.eCommerceRoot,
            children: [
              {
                path: "goods-sales",
                element:
                  <PrivateRoute
                    requiredRoles={[keycloakRealmRoles.PAYMENT_STATISTICS]}
                    pageName={'модуля e-commerce'}
                  >
                    <GoodsSales />
                  </PrivateRoute>
              },
              {
                path: "sales-volume",
                element:
                  <PrivateRoute
                    requiredRoles={[keycloakRealmRoles.PAYMENT_STATISTICS]}
                    pageName={'модуля e-commerce'}
                  >
                    <SalesVolume />
                  </PrivateRoute>
              },
              {
                path: "revenue-by-month",
                element:
                  <PrivateRoute
                    requiredRoles={[keycloakRealmRoles.PAYMENT_STATISTICS]}
                    pageName={'модуля e-commerce'}
                  >
                    <RevenueByMonth />
                  </PrivateRoute>
              },
              {
                path: "indicators-cumulative-total-by-months",
                element:
                  <PrivateRoute
                    requiredRoles={[keycloakRealmRoles.PAYMENT_STATISTICS]}
                    pageName={'модуля e-commerce'}
                  >
                    <IndicatorsCumulativeTotalByMonths />
                  </PrivateRoute>
              },
              {
                path: "summarized-sales-figures",
                element:
                  <PrivateRoute
                    requiredRoles={[keycloakRealmRoles.PAYMENT_STATISTICS]}
                    pageName={'модуля e-commerce'}
                  >
                    <SummarizedSalesFigures />
                  </PrivateRoute>
              }
            ]
          },*/
          /*{
            path: rootPaths.eLearningRoot,
            children: [
              {
                path: "users-list",
                element:
                  <PrivateRoute
                    requiredRoles={[
                      keycloakRealmRoles.LEARN_STATISTICS,
                      keycloakRealmRoles.USERS_VIEW
                    ]}
                    pageName={'модуля e-learning'}
                  >
                    <></>
                  </PrivateRoute>
              }
              /!*{
                path: paths.courseDetails(':courseId'),
                element: <CourseDetails />
              }*!/
            ]
          },*/
          /*{
            path: rootPaths.kinescope,
            children: [
              {
                path: "projects-structure",
                element:
                  <PrivateRoute requiredRoles={[]} pageName={'модуля Kinescope'}>
                    <ProjectStructures />
                  </PrivateRoute>
              }
            ]
          }*/
        ]
      },
      {
        path: '*',
        element: <Navigate to={paths.error404} replace />
      }
    ]
  }
];

export const router = createBrowserRouter(routes, {
  basename: process.env.PUBLIC_URL
});

export default routes;
